<template>
  <div>
  <div class="d-inline-flex jumbotron min-vw-50 p-3">
    <div class="form-horizontal">
      <!--Form Begins -->
      <fieldset>
      <!-- Form Name -->
      <legend>Look for submissions to critique</legend>
      <!-- Select Format -->
      <div class="form-group">
        <label class="control-label" for="format">Pick a format you want to give feedback on.</label>
          <select id="format" name="format" class="form-control min-vw-50" v-model="investigate.format" required>
            <option value="Image">Image</option>
            <option value="Sound">Sound</option>
            <option value="Video">Video</option>
            <option value="Creative Writing">Creative Writing</option>
            <option value="Object">Object</option>
            <option value="Code">Code</option>
            <option value="Performance">Performance</option>
          </select>
      </div>
      <!-- Select Medium -->
      <!-- <div class="form-group  p-2">
        <label class="col-md-5 control-label" for="medium">Pick a medium you want to give feedback on.</label>
        <div class="col-md-4">
          <select id="medium" name="medium" class="form-control" v-model="investigate.medium">
            <option value="Digital Art">Digital Art</option>
            <option value="Traditional Art">Traditional Art</option>
            <option value="Photograph">Photograph</option>
            <option value="Vector Art">Vector Art</option>
            <option value="Stationary CG">Stationary CG</option>
            <option value="Motion CG">Motion CG</option>
            <option value="VFX">VFX</option>
            <option value="Film">Film</option>
            <option value="Traditional Animation">Traditional Animation</option>
            <option value="Digital Animation">Digital Animation</option>
            <option value="Other Stationary Visual">Other Stationary Visual</option>
            <option value="Other Motion Visual">Other Motion Visual</option>
            <option value="Audio">Audio</option>
            <option value="Music">Music</option>
            <option value="Music - Transcription">Music - Transcription</option>
            <option value="Music - Performance">Music - Performance</option>
            <option value="Other Music or Audio">Other Music or Audio</option>
            <option value="Prose">Prose</option>
            <option value="Poetry">Poetry</option>
            <option value="Script or Screenplay">Script or Screenplay</option>
            <option value="Lyrics or Libretto">Lyrics or Libretto</option>
            <option value="Sculpture">Sculpture</option>
            <option value="Origami">Origami</option>
            <option value="Other Modelling">Other Modelling</option>
            <option value="Creative Coding">Creative Visual Coding</option>
            <option value="Creative Coding">Creative Audio Coding</option>

            <option value="Web Design">Web Design</option>
            <option value="Dance">Dance</option>
            <option value="Acting">Acting</option>

            <option value="Performance Art">Performance Art</option>
            <option value="Other Performance">Other Performance</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div> -->

      <!-- Tags Textarea -->
      <!--<div class="form-group  p-2">
        <label class="col-md-4 control-label" for="tags">Add some tags to further specify your investigate. Separate tags by one comma (,) and a space.</label>
        <div class="col-md-4">
          <textarea class="form-control" id="tags" name="tags" placeholder="Mona Lisa, Impressionist, Purple" v-model="investigate.tags"></textarea>
        </div>
      </div> -->

      <!-- Age Suitability Selector -->
      <div class="form-group p-3">
        <label class="control-label" for="age">Pick the maximum age rating that you feel comfortable seeing.</label>
        <div>
          <select id="age" name="age" class="form-control min-vw-50" v-model="investigate.ageRating" required>
            <option value="0">Suitable for all ages</option>
            <option value="1" v-if="user.censor >= 1">13+</option>
            <option value="2" v-if="user.censor >= 2">16+</option>
            <option value="3" v-if="user.censor >= 3">18+</option>
            <option value="4" v-if="user.censor >= 4">NSFW</option>
          </select>
        </div>
      </div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="Check" value="true" v-model="investigate.fan" checked>
        <label class="form-check-label" for="Check">Check this box if you're happy to see fan content.</label>
      </div>

      <!-- Button -->
      <br>
      <div class="form-group  p-2">
        <div class="justify-content-center">
          <button type="submit" id="submit" name="submit" class="btn btn-primary" value="Submit" @click="submitInvestigation">Submit</button>
        </div>
      </div>

      </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvestigateForm',
  props: {
    msg: String
  },
  methods: {
    submitInvestigation () {
      const investigateData = {
        link: this.investigate.link,
        name: this.investigate.name,
        ageRating: this.investigate.ageRating,
        format: this.investigate.format,
        medium: this.investigate.medium,
        completion: this.investigate.completion,
        focus: this.investigate.focus,
        questions: this.investigate.questions,
        fan: this.investigate.fan,
        tags: this.investigate.tags
      }
      this.$store.dispatch('feedbackkerSubmissions/submissionInvestigate', investigateData)
    }
  },
  data: () => ({
    investigate: {
      format: '',
      medium: '',
      fan: false,
      tags: '',
      ageRating: ''
    },
    user: {
      censor: localStorage.atCensor
    }
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
h3
  margin 40px 0 0

ul
  list-style-type none
  padding 0

li
  display inline-block
  margin 0 10px

a
  color #42b983
</style>
