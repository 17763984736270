<template>
  <div>
    <InvestigateForm v-if="auth.atToken"/>
  </div>
</template>

<script>
// @ is an alias to /src
import InvestigateForm from '@/components/InvestigateForm.vue'

export default {
  name: 'Find',
  components: {
    InvestigateForm
  },
  data: () => ({
    auth: {
      atToken: localStorage.getItem('atToken')
    }
  })
}

</script>
